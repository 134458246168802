import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import Button from '@strava/ui/Button';
import Spinner from '@strava/ui/Spinner';
import Switch from '@strava/ui/Switch';
import createNetworkingClient from 'utils/networking-client';
import I18n from 'utils/I18n';
import { trackV2 } from 'utils/analytics';

import StartAndEndSlider from './components/StartAndEndSlider';
import SelectHiddenMap from './components/SelectHiddenMap';
import styles from './styles.scss';

function EditMapVisibilityApp({ activity, paths }) {
  const i18nPrefix = 'strava.activities.edit_map_visibility.';
  const [error, setError] = useState(false);

  // Selected slider values
  const [startValue, setStartValue] = useState(null);
  const [endValue, setEndValue] = useState(null);
  const [mapHidden, setMapHidden] = useState(null);

  const [streams, setStreams] = useState({
    distance: [],
    privacy: [],
    latlng: []
  });

  const trackEvent = (action, element = null, properties = null) => {
    trackV2({
      category: 'privacy_settings',
      page: 'activity_detail_edit_map_visibility',
      action,
      ...(element && { element }),
      ...(properties && { properties })
    });
  };

  useEffect(() => {
    trackEvent('screen_enter', null, { activity_id: activity.id });
    async function loadStreams() {
      const response = await createNetworkingClient().get(paths.streamsPath, {
        params: {
          stream_types: ['distance', 'privacy', 'latlng']
        }
      });
      setStreams(response.data);
    }
    loadStreams();
  }, []);

  // Range of indices for each slider
  const sliderDomains = useMemo(() => {
    let start;
    let end;
    const activityDistance = streams.distance[streams.distance.length - 1];
    // If the activity is under 1600m then the slider domain should be the length of the entire activity
    if (activityDistance <= 1600) {
      start = [0, streams.distance.length - 1];
      end = [0, streams.distance.length - 1];
    } else {
      // If the activity's hidden area (start or end) is larger than one mile due to an existing privacy zone,
      // domain should be that length. Otherwise, the domain should end where the activity > 1600m.
      const startOneMileIdx = streams.distance.findIndex((ele) => ele > 1600);
      const endOneMileIdx = streams.distance
        .slice()
        .reverse()
        .findIndex((ele) => activityDistance - ele > 1600);
      const startHiddenIdx = streams.privacy.findIndex((ele) => ele === 0);
      const endHiddenIdx = streams.privacy
        .slice()
        .reverse()
        .findIndex((ele) => ele === 0);
      start =
        startHiddenIdx > startOneMileIdx
          ? [0, startHiddenIdx]
          : [0, startOneMileIdx];
      end =
        endHiddenIdx > endOneMileIdx ? [0, endHiddenIdx] : [0, endOneMileIdx];
    }
    return { start, end };
  }, [streams]);

  // Initial slider indices that are selected
  useEffect(() => {
    if (!streams.privacy.length) return;
    if (streams.privacy.findIndex((ele) => ele === 0) !== -1) {
      setStartValue(streams.privacy.findIndex((ele) => ele === 0));
      setEndValue(
        streams.privacy
          .slice()
          .reverse()
          .findIndex((ele) => ele === 0)
      );
      setMapHidden(false);
    } else {
      setStartValue(sliderDomains.start[0]);
      setEndValue(sliderDomains.end[0]);
      setMapHidden(true);
    }
  }, [streams, sliderDomains]);

  const getEndDistanceIdx = (sliderEndValue) =>
    streams.distance.length - 1 - sliderEndValue;

  const updateStreamStart = (value) => {
    setStartValue(value);
    const overlap = value - getEndDistanceIdx(endValue);
    if (overlap >= 0) {
      const newEndValue = endValue - overlap;
      setEndValue(newEndValue);
    }
  };

  const updateStreamEnd = (value) => {
    setEndValue(value);
    const overlap = startValue - getEndDistanceIdx(value);
    if (overlap >= 0) {
      const newStartValue = startValue - overlap;
      setStartValue(newStartValue);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let subStreams;
    const streamStart = [0, startValue];
    const streamEnd = [
      getEndDistanceIdx(endValue),
      streams.distance.length - 1
    ];

    trackEvent('click', 'save', {
      activity_id: activity.id,
      ...(!mapHidden && {
        start_point: streams.distance[startValue],
        end_point:
          streams.distance[streams.distance.length - 1] -
          streams.distance[getEndDistanceIdx(endValue)]
      }),
      ...(mapHidden && { map_visibility: 'only_you' })
    });

    // Currently, the way this is set up means that users can't hide a single start/end point on Web.
    // The smallest possible section to hide at the start or end is two points, sending back [0, 1] or
    // [endIdx-1, endIdx] as the subStream.
    if (streamStart[1] === streamEnd[0] || mapHidden) {
      subStreams = [[streamStart[0], streamEnd[1]]];
    } else if (
      streamStart[1] === 0 &&
      streamEnd[0] === streams.distance.length - 1
    ) {
      subStreams = [];
    } else if (streamStart[1] === 0) {
      subStreams = [streamEnd];
    } else if (streamEnd[0] === streams.distance.length - 1) {
      subStreams = [streamStart];
    } else {
      subStreams = [streamStart, streamEnd];
    }

    createNetworkingClient()
      .put(paths.streamPrivacyPath, {
        sub_streams: subStreams,
        length: streams.distance.length
      })
      .then(() => {
        window.location.href = paths.activityPath;
      })
      .catch(() => setError(true));
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftSide}>
        <div className={styles.header}>{I18n.t(`${i18nPrefix}header`)}</div>
        <div className={styles.activityTitle}>{activity.name}</div>
        <div className={styles.details}>
          {I18n.t(`${i18nPrefix}use_slider`)}
        </div>
        <div className={styles.details}>
          {I18n.t(`${i18nPrefix}hidden_gray`)}
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <Button
            type="submit"
            variant="primaryOutline"
            className={styles.saveButton}
          >
            {I18n.t(`${i18nPrefix}save`)}
          </Button>
          <Button
            type="button"
            variant="icon"
            className={styles.cancelButton}
            onClick={() => {
              trackEvent('click', 'cancel', { activity_id: activity.id });
              window.location.href = paths.activityPath;
            }}
          >
            {I18n.t(`${i18nPrefix}cancel`)}
          </Button>
        </form>
        {error ? (
          <div className={styles.error}>{I18n.t(`${i18nPrefix}error`)}</div>
        ) : null}
        <a
          href="https://support.strava.com/hc/en-us/articles/115000173384"
          className={styles.links}
          onClick={() =>
            trackEvent('click', 'learn_more', {
              activity_id: activity.id,
              article_id: 115000173384
            })
          }
        >
          {I18n.t(`${i18nPrefix}learn_more`)}
        </a>
        <br />
        <a
          href={paths.privacySettingsPath}
          className={styles.links}
          onClick={() =>
            trackEvent('click', 'privacy_settings', {
              activity_id: activity.id
            })
          }
        >
          {I18n.t(`${i18nPrefix}update_settings`)}
        </a>
      </div>
      {startValue === null || endValue === null ? (
        <div className={styles.spinnerContainer}>
          <Spinner size={30} />
        </div>
      ) : (
        <div className={styles.rightSide}>
          <StartAndEndSlider
            activityId={activity.id}
            type="start"
            domain={sliderDomains.start}
            sliderValue={startValue}
            distanceStream={streams.distance}
            onUpdate={updateStreamStart}
            disabled={mapHidden}
          />
          <StartAndEndSlider
            activityId={activity.id}
            type="end"
            domain={sliderDomains.end}
            sliderValue={endValue}
            distanceStream={streams.distance}
            onUpdate={updateStreamEnd}
            disabled={mapHidden}
          />
          <div className={styles.switchContainer}>
            <div className={styles.hideEntireMapLabel}>
              {I18n.t(`${i18nPrefix}hide_entire_map`)}
            </div>
            <Switch
              name="hide-entire-map-switch"
              className={styles.switch}
              checked={mapHidden}
              onClick={() => {
                trackEvent('click', 'hide_entire_map', {
                  activity_id: activity.id,
                  map_visibility: !mapHidden ? 'only_you' : 'everyone'
                });
                setMapHidden(!mapHidden);
              }}
            />
          </div>
          <SelectHiddenMap
            activityId={activity.id}
            activityLatLng={streams.latlng}
            hideEntireMap={mapHidden}
            startIdx={startValue}
            endIdx={getEndDistanceIdx(endValue)}
          />
        </div>
      )}
    </div>
  );
}

EditMapVisibilityApp.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  }).isRequired,
  paths: PropTypes.shape({
    activityPath: PropTypes.string,
    privacySettingsPath: PropTypes.string,
    streamsPath: PropTypes.string,
    streamPrivacyPath: PropTypes.string
  }).isRequired
};

export default EditMapVisibilityApp;
