import { logMessage } from './sentry';

export function trackV2({ category, page, action, element, properties = {} }) {
  try {
    window.Strava.ExternalAnalytics.trackV2({
      category,
      page,
      action,
      element,
      properties
    });
  } catch (e) {
    logMessage(
      `Could not execute Strava.ExternalAnalytics.trackV2 event with the category:action ${category}:${action}`
    );
  }
}

export default {
  trackV2
};
