/* eslint-disable no-underscore-dangle */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { withMap, MAP_STYLES } from '@strava/ui/Map';
import Button from '@strava/ui/Button';
import I18n from 'utils/I18n';

import styles from './StyleNav.scss';

class MenuControl {
  constructor({ node }) {
    this._node = node;
  }

  onAdd(map) {
    this._map = map;
    this._container = document.createElement('div');
    this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
    this._container.appendChild(this._node);

    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    delete this._map;
  }
}

const StyleNav = ({ map }) => {
  const menuNode = useRef();
  const [mapStyle, setMapStyle] = useState(MAP_STYLES.standard);

  useEffect(() => {
    const ctrlPolygon = new MenuControl({ node: menuNode.current });
    map.addControl(ctrlPolygon, 'top-right');
  }, []);

  function handleClick(style) {
    setMapStyle(style);
    map.setStyle(style);
  }

  return (
    <div className={styles.nav} ref={menuNode}>
      <Button
        type="button"
        className={`btn-xs ${styles.btn}`}
        onClick={() => handleClick(MAP_STYLES.standard)}
        disabled={mapStyle === MAP_STYLES.standard}
      >
        {I18n.t('strava.maps.custom_control.standard')}
      </Button>
      <Button
        type="button"
        className={`btn-xs ${styles.btn}`}
        onClick={() => handleClick(MAP_STYLES.satellite)}
        disabled={mapStyle === MAP_STYLES.satellite}
      >
        {I18n.t('strava.maps.custom_control.satellite')}
      </Button>
    </div>
  );
};

StyleNav.propTypes = {
  map: PropTypes.shape().isRequired
};

export default withMap(StyleNav);
