import axios from 'axios';

function baseUrl() {
  const port = window.location.port === '' ? '' : `:${window.location.port}`;

  return `${window.location.protocol}//${window.location.hostname}${port}`;
}

export function csrfToken() {
  return document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content');
}

export default function createNetworkingClient(options = {}) {
  return axios.create({
    baseUrl: baseUrl(),
    /**
     * The Axios timeout was set to 5s, causing requests taking longer than 5s to error out, and therefore causing Sentry errors.
     * Several factors can cause a slow request, such as slow network speeds or server.
     * Bumping the timeout to 30s should help account for these slow requests but also ensure that
     * we are not waiting for request resources for an indefinite time.
     * */
    timeout: 30000, // 30 seconds
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': csrfToken()
    },
    ...options
  });
}
