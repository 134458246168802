import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Slider, Rail, Handles, Tracks } from 'react-compound-slider';
import Button from '@strava/ui/Button';
import ActionsArrowLeftNormalXsmall from '@strava/icons/ActionsArrowLeftNormalXsmall';
import ActionsArrowRightNormalXsmall from '@strava/icons/ActionsArrowRightNormalXsmall';
import I18n from 'utils/I18n';
import { trackV2 } from 'utils/analytics';

import Handle from './Handle';
import Track from './Track';
import styles from './styles.scss';

const StartAndEndSlider = ({
  activityId,
  type,
  domain,
  sliderValue,
  distanceStream,
  disabled,
  onUpdate = () => {}
}) => {
  const i18nPrefix = 'strava.activities.edit_map_visibility.';
  const isStart = type === 'start';
  const isMetric = I18n.measurementUnits() === 'metric';

  const trackEvent = (action, element = null, properties = null) => {
    trackV2({
      category: 'privacy_settings',
      page: 'activity_detail_edit_map_visibility',
      action,
      ...(element && { element }),
      ...(properties && { properties })
    });
  };

  // Selected distance stream index
  const getDistanceStreamIdx = (sliderIdx) => {
    if (isStart) {
      return sliderIdx;
    }
    return distanceStream.length - 1 - sliderIdx;
  };

  const getDistanceHidden = (sliderIdx) => {
    const lastIdx = distanceStream.length - 1;
    const metersHidden = isStart
      ? distanceStream[getDistanceStreamIdx(sliderIdx)]
      : distanceStream[lastIdx] -
        distanceStream[getDistanceStreamIdx(sliderIdx)];
    if (isMetric) {
      return Math.round(metersHidden);
    }
    return I18n.distanceFormatter()
      .convert(metersHidden)
      .toFixed(2);
  };

  const [distanceHidden, setDistanceHidden] = useState(
    getDistanceHidden(sliderValue)
  );

  const maxLabel = useMemo(() => {
    const distance = getDistanceHidden(domain[1]);

    if (isMetric) {
      return { label: `${i18nPrefix}scale_meters`, value: { distance } };
    }
    return {
      label: `${i18nPrefix}scale_mile`,
      value: { count: distance }
    };
  }, []);

  const selectionLabel = () => {
    let selected;
    if (disabled) {
      selected = { label: `${i18nPrefix}selection_not_available` };
    } else if (sliderValue === domain[0]) {
      selected = { label: `${i18nPrefix}selection_not_hidden` };
    } else if (isMetric) {
      selected = {
        label: `${i18nPrefix}selection_meters_hidden`,
        value: { distance: distanceHidden }
      };
    } else {
      selected = {
        label: `${i18nPrefix}selection_mile_hidden`,
        value: { count: distanceHidden }
      };
    }
    return selected;
  };

  useEffect(() => {
    setDistanceHidden(getDistanceHidden(sliderValue));
  }, [sliderValue]);

  return (
    <div className={styles.container}>
      <div className={styles.controls}>
        <div
          className={`${styles.hideStartEndLabel} ${
            disabled ? styles.disabled : ''
          }`}
        >
          {isStart
            ? I18n.t(`${i18nPrefix}hide_start`)
            : I18n.t(`${i18nPrefix}hide_end`)}
        </div>
        <div className={styles.selectionControls}>
          <Button
            type="button"
            variant="icon"
            onClick={() => {
              trackEvent(
                'click',
                isStart ? 'start_slider_left_arrow' : 'end_slider_left_arrow',
                { activity_id: activityId }
              );
              if (!disabled) onUpdate(sliderValue - 1);
            }}
            data-testid="control-button"
          >
            <ActionsArrowLeftNormalXsmall />
          </Button>
          <div
            data-testid="selection_label"
            className={`${styles.selectionLabel} ${
              disabled ? styles.disabled : ''
            }`}
          >
            {I18n.t(selectionLabel().label, selectionLabel().value)}
          </div>
          <Button
            type="button"
            variant="icon"
            onClick={() => {
              trackEvent(
                'click',
                isStart ? 'start_slider_right_arrow' : 'end_slider_right_arrow',
                { activity_id: activityId }
              );
              if (!disabled) onUpdate(sliderValue + 1);
            }}
            data-testid="control-button"
          >
            <ActionsArrowRightNormalXsmall />
          </Button>
        </div>
      </div>

      <div className={styles.wrapper}>
        <Slider
          mode={1}
          step={1}
          domain={domain}
          onUpdate={(newVal) => onUpdate(newVal[0])}
          onSlideEnd={() =>
            trackEvent('interact', isStart ? 'start_slider' : 'end_slider', {
              activity_id: activityId
            })
          }
          values={[sliderValue]}
          className={styles.slider}
          disabled={disabled}
        >
          <div className={styles.graphicWrap}>
            <div
              className={`${styles.rail} ${disabled ? styles.disabled : ''}`}
            />
          </div>

          <Tracks left={true} right={false}>
            {({ tracks, getTrackProps }) => (
              <div className={styles.graphicWrap}>
                {tracks.map(({ id, source, target }) => (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                    disabled={disabled}
                  />
                ))}
              </div>
            )}
          </Tracks>

          {/*
            whe need to use Rail component here so that we can add getRailProps
            which will add mouse interactions
          */}
          <Rail>
            {({ getRailProps }) => (
              <div className={styles.graphicWrap}>
                <div className={styles.railHitarea} {...getRailProps()} />
              </div>
            )}
          </Rail>

          <Handles>
            {({ handles, getHandleProps }) => (
              <div className={styles.graphicWrap}>
                <Handle
                  key={handles[0].id}
                  handle={handles[0]}
                  domain={domain}
                  getHandleProps={getHandleProps}
                />
              </div>
            )}
          </Handles>
        </Slider>
      </div>

      <div className={styles.scale}>
        <span data-testid="off_label">{I18n.t(`${i18nPrefix}scale_off`)}</span>
        <span data-testid="max_label">
          {I18n.t(maxLabel.label, maxLabel.value)}
        </span>
      </div>
    </div>
  );
};

StartAndEndSlider.propTypes = {
  activityId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  domain: PropTypes.arrayOf(PropTypes.number).isRequired,
  sliderValue: PropTypes.number.isRequired,
  distanceStream: PropTypes.arrayOf(PropTypes.number).isRequired,
  disabled: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func
};

export default StartAndEndSlider;
