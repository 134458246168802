import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { color } from '@strava/ui-tokens/js';
import Map, { useMap } from '@strava/ui/Map';
import Polyline from '@strava/ui/Map/Polyline';
import Marker from '@strava/ui/Map/Marker';

import primaryStartMarker from '@strava/ui/Map/images/map-marker-start.png';
import primaryEndMarker from '@strava/ui/Map/images/map-marker-end.png';

import FitBounds from 'components/map/FitBounds';
import StyleNav from 'components/map/StyleNav';

import { trackV2 } from 'utils/analytics';
import I18n from 'utils/I18n';
import styles from './styles.scss';

function MapAnalytics({ activityId }) {
  const map = useMap();

  useEffect(() => {
    map.on('zoomstart', (e) => {
      if (e.source !== 'fitBounds') {
        trackV2({
          category: 'activity_detail',
          page: 'activity_detail_edit_map_visibility',
          action: 'click',
          element: 'map_zoom',
          properties: { activity_id: activityId }
        });
      }
    });
    map.on('dragstart', () => {
      trackV2({
        category: 'activity_detail',
        page: 'activity_detail_edit_map_visibility',
        action: 'pan',
        element: 'map',
        properties: { activity_id: activityId }
      });
    });
  }, []);

  return null;
}

function SelectHiddenMap({
  activityId,
  activityLatLng,
  hideEntireMap,
  startIdx,
  endIdx
}) {
  const i18nPrefix = 'strava.activities.edit_map_visibility.';

  return (
    <Map
      latLng={activityLatLng}
      className={styles.map}
      options={{
        minZoom: 9,
        maxZoom: 18
      }}
    >
      <StyleNav />

      <FitBounds
        latlng={activityLatLng}
        start={activityLatLng[0]}
        end={activityLatLng.slice(-1)[0]}
      />

      <Polyline
        latLng={activityLatLng}
        id="primary-activity-polyline"
        style={{
          lineColor: hideEntireMap
            ? color.colorExtendedNeutralN2
            : color.colorCoreO3
        }}
      />

      <Marker latLng={activityLatLng[0]}>
        <img
          src={primaryStartMarker}
          alt={I18n.t(`${i18nPrefix}start`)}
          className={styles.primaryMarker}
        />
      </Marker>
      <Marker latLng={activityLatLng.slice(-1)[0]}>
        <img
          src={primaryEndMarker}
          alt={I18n.t(`${i18nPrefix}end`)}
          className={styles.primaryMarker}
        />
      </Marker>

      {!hideEntireMap && (
        <>
          <Polyline
            latLng={activityLatLng.slice(0, startIdx + 1)}
            id="hidden-start-polyline"
            style={{
              lineColor: color.colorExtendedNeutralN2,
              outlineWidth: 0
            }}
          />
          <Polyline
            latLng={activityLatLng.slice(endIdx, activityLatLng.length)}
            id="hidden-end-polyline"
            style={{
              lineColor: color.colorExtendedNeutralN2,
              outlineWidth: 0
            }}
          />
          <MapAnalytics activityId={activityId} />
        </>
      )}
    </Map>
  );
}

SelectHiddenMap.propTypes = {
  activityId: PropTypes.number.isRequired,
  activityLatLng: PropTypes.arrayOf(PropTypes.array).isRequired,
  hideEntireMap: PropTypes.bool.isRequired,
  startIdx: PropTypes.number.isRequired,
  endIdx: PropTypes.number.isRequired
};

export default SelectHiddenMap;
